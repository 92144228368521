import { H2, P } from "components/Heading";
import Link from "components/Link";
import Paragraph from "components/Paragraph";
import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import TeamCard from "./TeamCard";

import brigitte from './brigitte.jpg';
// import christine from './christine.jpg';
import magdalena from './magdalena.jpg';
//import julia from './julia.jpg';
import leo from './leo.jpg';
import matthias from './matthias.jpg';
import ursula from './ursula.jpg';
//import luzie from './luzie.jpg';
import clemens from './clemens.jpg';
import lukas from './lukas.jpg';
import felix from './felix.jpg';

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  justify-items: stretch;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`

function shuffle(array) {
   for (let i = array.length - 1; i > 0; i--) { 
       const j = Math.floor(Math.random() * (i + 1));
       const temp = array[i];
       array[i] = array[j];
       array[j] = temp;
   }
   return array;
}

export default function(props) {
  const items = shuffle([
    { img: leo, name: 'Leo Wagner', href: `leo-wagner` },
    //{ img: julia, name: 'Julia Scharinger-Schöttel', href: `julia-scharinger-schoettel` },
    { img: brigitte, name: 'Brigitte Vettori', href: `brigitte-vettori` },
    //{ img: christine, name: 'Christine Steindorfer', href: `christine-steindorfer` },
    { img: magdalena, name: 'Magdalena Lettner', href: `magdalena-lettner` },
    { img: matthias, name: 'Matthias Treitler', href: `matthias-treitler` },
    //{ img: ursula, name: 'Ursula Kermer', href: `ursula-kermer` },
    //{ img: luzie, name: 'Luzie Lahtinen', href: `luzie-lahtinen` },
    { img: clemens, name: 'Clemens Lafenthaler', href: `clemens-lafenthaler` },
    { img: lukas, name: 'Lukas Matuschek', href: `lukas-matuschek` },
    { img: felix, name: 'Felix Parthl', href: `felix-parthl` },
  ])

  return <Box>
    <H2 py={2}>
      <Box as="span">Das Team</Box>
    </H2>

    <Grid>
      {items.map(({ img, name, href }, index) => (
        <TeamCard
          key={name}
          link={`${href}`}
        >
          <TeamCard.Image
            alt={`${name}`}
            src={img}
          />
          <TeamCard.Title pt={3}>{name}</TeamCard.Title>
        </TeamCard>
      ))}
    </Grid>
    <H2 py={2}>
      <Box as="span">Unter<P>stützer*innen</P></Box>
    </H2>
    <Paragraph ml={[0, 200]} width={["auto", "100%"]}>
      Wir freuen uns, dass uns viele Menschen laufend zur Seite stehen, so etwa Praktikant*innen und Stadtbegeisterte, die uns punktuell helfen.
    </Paragraph>

    <H2 py={2}>
      <Box as="span"><P>Mitdenker*innen</P> aus früheren Jahren...</Box>
    </H2>
    <Paragraph ml={[0, 200]} width={["auto", "100%"]}>
      space and place erfindet sich immer wieder neu. <br />
    Seit der Gründung unserer Stadtinitiative haben im Kernteam u.a. Anna Pospischil, Birgit Wagner, <Link href="//wortart.at">Christine Steindorfer</Link>, Corinna Wachtberger, <Link href="//whoosh.wien">Eugene Quinn</Link>, Fidelia Gartner, <Link href="//paradeisa.at">Isabella Schieszler-Lotschak</Link>, Julia Eckstein, <Link href="//mindkitchen.info">Julia Scharinger-Schöttel</Link>, Kora Discher, Luzie Lahtinen, <Link href="//oe1.orf.at/team/monika_kalcsics ">Monika Kalcsics</Link>, Monika Kehrer, Nicole Boigner und <Link href="//raum-im-raum.info">Ursula Kermer</Link> Spuren hinterlassen. Manche waren über Jahre hinweg bei space and place aktiv.
    </Paragraph>
  </Box>
}

