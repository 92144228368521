import { H3 } from "components/Heading";
import Image from "components/Image";
import Link from "components/Link";
import Text from "components/Text";
import React from "react";
import { Flex } from "rebass";
import photo from './magdalena.jpg';



export default function(props) {
  return <Flex {...props} flexDirection={["column", "row"]} justifyContent="center" alignItems="center">
    <Image src={photo} width="200" p={2} />
    <Flex p={2} flexDirection="column" alignItems="flex-start" justifyContent="center">
      <Text fontSize={2} color="secondary" css={{textTransform: 'uppercase'}}>Pressekontakt</Text>
      <H3 fontSize={3} lineHeight={1} my={2} >Magdalena Lettner</H3>
      {/*<Text color="secondary" my={3}>T_+43 699 17 177 404</Text>*/}
      <Text color="primary"><Link href="mailto: komm@spaceandplace.at">komm@spaceandplace.at</Link></Text>

      </Flex>

    </Flex>
}
